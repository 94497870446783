import { subscribe, notify, ORDER_MODE } from 'OK/reactions/ReactionController';
import { logReactionError, logReaction } from 'OK/reactions/ReactionLogger';
import { add, remove, collections } from 'OK/LinkedHooksStore';
import ShortcutMenuReact from 'OK/ShortcutMenuReact';
import { Surprise } from 'OK/Surprise';
import { parent, firstByClass } from 'OK/utils/dom';
import { formatNumber } from 'OK/utils/vanilla';
import l10n from 'OK/pts!accessibility';

// Заранее инициируем загрузку связанного скрипта
import('OK/ExpressReactionsPopup');

var REACTION_LIKE_ID = 0;
var REACTION_PRIVATE_LIKE_ID = 10;

var _alf;

var UNLIKE = 't,unlike';
var HIDE_REACT_SC = 't,hide-react-sc';
var LIKE = 't,.l';

var collectionName = collections.REACTION_COMPONENTS;

export default class ReactComponent {
    constructor() {
        this.onClick = this.onClick.bind(this);
        this.updateParams = this.updateParams.bind(this);
    }

    activate(el) {
        /**
         * @type {HTMLElement}
         */
        this.el = el;

        this.blockHookId = OK.hookModel.getNearestBlockHookId(el);
        this.blockHook = document.getElementById('hook_Block_' + this.blockHookId);
        this.reactScMenuId = 'ShMenuExpressReact-' + this.blockHookId;

        this.refId = el.getAttribute('data-like-reference-id');
        this.hookId = el.getAttribute('data-req');
        this.owner = el.getAttribute('data-owner');
        this.isPreview = el.getAttribute('data-preview') === '1';
        this.isButton = el.getAttribute('data-button') === '1';
        this.isCustomButton = el.getAttribute('data-custom-button') === '1';
        this.isSvgIcon = el.getAttribute('data-svg-icon') === '1';
        this.newDefaultIcon = el.getAttribute('data-new-default-icon');
        this.isRedesign2023 = el.getAttribute('data-is-redesign2023') === '1';
        this.ordinaryView = el.getAttribute('data-ordinary-view') === '1';
        this.likeReactionData = {
            reactionId: REACTION_LIKE_ID,
            icon: el.getAttribute('data-like-icon'),
            text: el.getAttribute('data-like-tx')
        };
        this.customReactionData = {
            reactionId: +el.getAttribute('data-custom-reaction-id'),
            icon: el.getAttribute('data-custom-reaction-icon'),
            text: el.getAttribute('data-custom-reaction-tx'),
            surprise: el.getAttribute('data-custom-reaction-surprise')
        };

        var reactionId = parseInt(el.getAttribute('data-react'), 10);
        this.reactionId = isNaN(reactionId) ? null : reactionId;

        this.reactionCount = parseInt(el.getAttribute('data-count'), 10) || 0;
        this.likeContext = el.getAttribute('data-lc');
        this.logLocation = el.getAttribute('data-ll');

        if (!this.isButton && !this.isCustomButton) {
            add(this.refId, collectionName, this.hookId);
        }

        if (!this.isButton) {
            el.addEventListener('click', this.onClick);
        }

        this.syncPlayersLikes();
        this.updateParams();

        // Пополняем хранилище для ExpressReactionPopup
        ShortcutMenuReact.setReactComponent(this.blockHookId, this);

        // Откладывается назначение листенеров на основную ШМ,
        // потому что целевой модуль активируется после текущего
        setTimeout(this.addReactSMListeners.bind(this));

        // Назначаем листенеры для ШМ списка поклассивших
        this.addLikesSMListeners();

        if (this.isCustomButton) {
            this.onReactionExternalChange = this.onReactionExternalChange.bind(this);
            subscribe(this.refId, this.onReactionExternalChange);
        }
    }

    deactivate() {
        remove(this.refId, collectionName, this.hookId);

        if (!this.isButton) {
            this.el.removeEventListener('click', this.onClick);
        }

        if (this.surprise) {
            this.surprise.destroy();
            this.surprise = null;
        }

        this.likersOnCountShm && this.likersOnCountShm.hide();

        // Очищаем хранилище для ExpressReactionPopup
        ShortcutMenuReact.removeReactComponent(this.blockHookId, this);
    }

    syncPlayersLikes() {
        if (!this.blockHook) return;

        var playerId = this.blockHook.getAttribute('data-player-id');

        playerId && runLinkedVideoCallbackFromJS({
            callbackId: playerId,
            liked: this.reactionId !== null,
            likeCount: this.reactionCount
        });
    }

    updateParams() {
        var sc = this.getReactScMenu();

        if (this.reactionId === null) {
            this.el.setAttribute('data-l', LIKE);
            this.el.setAttribute('aria-label', l10n.getLMsg('aria.label.component.reactions.like'));
        } else if (sc && sc.visible()) {
            this.el.setAttribute('data-l', HIDE_REACT_SC);
        } else {
            this.el.setAttribute('data-l', UNLIKE);
            this.el.setAttribute('aria-label', l10n.getLMsg('aria.label.component.reactions.unlike'));
        }
    }

    /**
     * @returns {ShortcutMenuReact}
     */
    getReactScMenu() {
        var sc = OK.hookModel.getHookById(this.reactScMenuId);

        return sc && sc.getInstance();
    }

    addReactSMListeners() {
        var sc = this.getReactScMenu();

        if (sc) {
            sc.menuShownListener = this.updateParams;
            sc.tmpReactionsActiveInstanceListener = this.updateParams;
        }
    }

    addLikesSMListeners() {
        var smEl = this.el.querySelector('.js-sleep-shm');

        if (smEl) {
            this.likersOnCountShm = new ShortcutMenuReact();
            this.likersOnCountShm.activate(smEl);
            this.likersOnCountShm.tmpReactionsActiveInstanceListener = this._activateSMListener.bind(this);
        }
    }

    _activateSMListener() {
        var sc = this.getReactScMenu();

        if (sc) {
            sc.tmpReactionsDisabled = this.likersOnCountShm.visible();

            if (!sc.tmpReactionsDisabled) {
                sc.checkAfterTmpReactionsDisabled();
            }
        }
    }

    /**
     * @param {MouseEvent} e
     */
    onClick(e) {
        var newReaction, self = this, isPreview = this.isPreview, sc = this.getReactScMenu();

        if (this.isCustomButton) {
            this._doChangeReaction(this.customReactionData, this.reactionId);

            var surpriseUrl = this.customReactionData.surprise;
            if (!surpriseUrl) {
                return;
            }

            if (this.surprise) {
                this.surprise.destroy();
                this.surprise = null;
            }

            this.surprise = new Surprise(this.el, surpriseUrl, e.clientX, e.clientY);
            this.surprise.run();

            return;
        }

        if (isPreview && sc && sc.visible()) {
            // было внешнее изменение реакции из ШМ, просто его скрываем,
            // само изменение реакции сохранится автоматом
            sc.hideMenu();
            return;
        }

        if (sc) {
            if (sc.visible()) {
                sc.hideMenu();
            }
            if (sc.isWaitingBeforeShow()) {
                sc.abortWaitingBeforeShow();
            }
        }

        if (this.reactionId !== null) {
            newReaction = null;
        } else {
            var btn = parent(e.target, 'js-klass-action', this.el, true);
            if (this.isCustomButton || (btn && (btn.getAttribute('data-klass-type') === 'private'))) {
                newReaction = this.customReactionData;
            } else {
                newReaction = this.likeReactionData;
            }
        }

        this._doChangeReaction(newReaction, this.reactionId);

    }

    /**
     * Для внешнего использования из ExpressReactionsPopup
     *
     * @param reactionData
     * @param prevReactionId
     * @param props
     * @returns {Promise}
     */
    changeReaction(reactionData, prevReactionId, props) {
        this.isPreview = (props.mode === ORDER_MODE.ONLY_DRAW);
        return this._doChangeReaction(reactionData, prevReactionId, props);
    }

    getCurrentReactionData() {
        if (this.reactionId !== null) {
            var txEl = firstByClass(this.el, 'widget_tx');
            return {
                reactionId: this.reactionId,
                icon: this.el.getAttribute('data-react-icon'),
                text: txEl ? txEl.textContent : ''
            };
        } else {
            return null;
        }
    }

    onReactionExternalChange(order) {
        var widgetListEl = parent(this.el, 'widget-list_i');
        if (widgetListEl) {
            widgetListEl.classList.toggle('invisible', order.reactionId !== null);
        }
        this.reactionId = order.reactionId;
    }

    refreshView(reactionData) {
        if (this.isButton || this.isCustomButton) {
            return;
        }

        var defaultClass = this.newDefaultIcon;

        var iconEl = firstByClass(this.el, 'widget_ico');

        var currentReactionData = this.getCurrentReactionData();

        // очищаем
        if (currentReactionData) {
            iconEl.classList.remove('__react', '__react-' + currentReactionData.icon);
        } else {
            if (defaultClass) {
                iconEl.classList.remove('__react', '__react-' + defaultClass);
            } else {
                iconEl.classList.remove('ic_klass');
                if (this.isSvgIcon && iconEl.classList.contains('__svg')) {
                    iconEl.classList.remove('__svg');
                }
            }
        }

        var countEl = firstByClass(this.el, 'js-count'), widgetEl = this.el.parentNode,
            privateKlassEl = firstByClass(this.el, 'js-private-klass'),
            isMultiBtn = this.el.classList.contains('__multi');

        if (!countEl && isMultiBtn) {
            // костыль для мульти-режима, без создания / удаления счётчика
            // не получается красиво сверстать
            countEl = document.createElement('span');
            countEl.className = 'widget_count widget_part js-count __empty';
            countEl.textContent = formatNumber(0);
            this.el.appendChild(countEl);
        }

        if (countEl) {
            var count = countEl.textContent.replace(/\s/g, '');

            if (!isNaN(count)) {
                count = parseInt(count, 10);

                if (currentReactionData) {
                    count = reactionData ? count : count - 1;
                } else {
                    count = reactionData ? count + 1 : count;
                }

                countEl.textContent = formatNumber(count);

                var invisible = (count === 0) && privateKlassEl;

                if (invisible) {
                    this.el.removeChild(countEl);
                }
            }
        }

        var txEl = firstByClass(this.el, 'widget_tx');

        if (!reactionData) {
            if (defaultClass) {
                iconEl.classList.add('__react', '__react-' + defaultClass);
            } else {
                if (!this.isRedesign2023 || this.isRedesign2023 && this.ordinaryView) {
                    iconEl.classList.add('ic_klass');
                }

                if (this.isSvgIcon) {
                    iconEl.classList.add('__svg');
                }
            }
            if (txEl) {
                txEl.textContent = this.el.getAttribute('data-unlike-tx');
            }
            widgetEl.removeAttribute('data-react');
            widgetEl.classList.remove('__active');
            if (privateKlassEl) {
                privateKlassEl.classList.remove('invisible');
            }
        } else {
            if (this.isRedesign2023 && reactionData.reactionId === REACTION_LIKE_ID) {
                if (this.ordinaryView) {
                    this.el.setAttribute('data-react-icon', reactionData.icon);
                    iconEl.classList.add('__react', '__react-' + reactionData.icon);
                } else {
                    this.el.setAttribute('data-react-icon', this.likeReactionData.icon);
                }
                widgetEl.setAttribute('data-react', this.likeReactionData.icon);
            } else {
                this.el.setAttribute('data-react-icon', reactionData.icon);
                iconEl.classList.add('__react', '__react-' + reactionData.icon);
                widgetEl.setAttribute('data-react', reactionData.icon);
            }

            if (txEl) {
                if (this.isRedesign2023 &&
                    reactionData.reactionId === REACTION_LIKE_ID || reactionData.reactionId === REACTION_PRIVATE_LIKE_ID) {
                    txEl.textContent = this.likeReactionData.text;
                } else {
                    txEl.textContent = reactionData.text;
                }
            }

            widgetEl.classList.add('__active');
            if (privateKlassEl) {
                privateKlassEl.classList.add('invisible');
            }
        }

    }

    /**
     *
     * @param reactionData
     * @param prevReactionId
     * @param props
     * @returns {Promise}
     * @private
     */
    _doChangeReaction(reactionData, prevReactionId, props) {

        this.refreshView(reactionData);

        var reactionId = reactionData ? reactionData.reactionId : null;
        prevReactionId = typeof prevReactionId !== 'undefined' ? prevReactionId : this.reactionId;

        var isSave = !props || (props.mode !== ORDER_MODE.ONLY_DRAW);

        if (isSave) {
            if (reactionId !== null) {
                logReaction('like', reactionId);
            } else {
                logReaction('unlike', prevReactionId);
            }
        }

        this.reactionId = reactionId;

        var payload = {
            lc: this.likeContext,
            ll: this.logLocation
        };

        if (props && props.payload) {
            Object.assign(payload, props.payload);
            delete props.payload;
        }

        return this._getImpressionId()
            .then(function (impressionId) {
                if (impressionId) {
                    payload.impressionId = impressionId;
                }
            })
            .then(function () {
                try {
                    notify(Object.assign({
                        refId: this.refId,
                        reactionId: reactionId,
                        prevReactionId: prevReactionId,
                        owner: this.owner,
                        payload: payload,
                        svgIcon: this.isSvgIcon
                    }, props));
                } catch (e) {
                    if (isSave) {
                        if (reactionId !== null) {
                            logReactionError('like-notify-error', e.message);
                        } else {
                            logReactionError('unlike-notify-error', e.message);
                        }
                    }
                }
            }.bind(this));

    }

    _getImpressionId() {
        var el = this.el;
        var wrapper = el.closest('.js-video-scope');

        if (wrapper) {
            var impressionId = wrapper.getAttribute('data-impression-id');
            if (impressionId) {
                return Promise.resolve(impressionId);
            }
        }

        return new Promise(function (resolve) {
            findAdv(el)
                .then(function (adv) {
                    return adv.getImpressionId();
                })
                .catch(function () {
                    return null;
                })
                .then(resolve);

            setTimeout(function () {
                resolve(null);
            }, 5000);
        });
    }
}

function loadAlf() {
    if (_alf) {
        return Promise.resolve(_alf);
    } else {
        // если alf не найден, запрашиваем его и сразу делаем reject
        // см. XPRM-16074 - очень много потерь классов на запросе OK/alf (около 0.5%)
        // а на самом деле нам impressionId не так уж и важен по сравнению с классами,
        // т.ч. можно и не тратить время на ожидание рекламного модуля
        import('OK/alf').then(m => m && m.default || m).then(function (alf) {
            _alf = alf;
        });
        return Promise.reject();
    }
}

function findAdv(el) {
    return new Promise(function (resolve, reject) {
        loadAlf()
            .then(function(alf) {
                return alf.findAdv(el);
            })
            .then(resolve, reject);
    });
}
